<template>
    <div class="audit-comic page-container flex-column">
        <!-- search-box -->
        <el-form class="search-box" ref="searchForm" align="center" :model="searchData" inline @submit.native.prevent>
            <el-form-item label="漫画等级：" prop="comicGrade">
                <el-select @change="searchHandle" v-model="searchData.comicGrade" placeholder="请选择漫画等级">
                    <el-option label="全部" :value="-1"></el-option>
                    <el-option
                        v-for="item in grades"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="[2].includes(level)" label="延迟状态：" prop="deferStatus">
                <el-select @change="searchHandle" v-model="searchData.deferStatus" placeholder="请选择延迟状态">
                    <el-option
                        v-for="item in status"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发布日期：" prop="comicGrade" v-if="false">
                <el-date-picker v-model="searchDateRange" style="width: 260px;" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item prop="queryText">
                <el-input style="width: 260px;"  v-model="searchData.queryText" placeholder="输入漫画或作者名称" clearable class="search-input" @keyup.enter.native.prevent="searchHandle">
                    <!-- <el-select prop="statuses" v-model="searchData.statuses" slot="prepend" style="width:115px" placeholder="选择状态">
                        <el-option v-for="(item,index) in auditType" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select> -->
                    <el-button slot="append" icon="el-icon-search" @click="searchHandle"></el-button>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button class="search-box-clear" type="text" @click="clearSearchHandle">清除搜索</el-button>
            </el-form-item>
        </el-form>
        <div class="comic-dispatch-line"></div>
        <!-- search-box-end -->
        <div class="audit-comic-tab">
            <el-tabs v-model="activeName">
                <template v-for="(tab, index) in auditType[level]">
                    <el-tab-pane :key="tab.id" :name="tab.id + ''">
                        <div slot="label">{{tab.name}} <span class="tab-icon">{{tab.total}}</span></div>
                        <keep-alive>
                            <auditComicTable :ref="'audit' + (index + 1)" :index="tab.id" @changeTypeNumber="changeTypeNumber" :isTable="isTable" />
                        </keep-alive>
                    </el-tab-pane>
                </template>
            </el-tabs>
            <div class="table-change-button">
                <tableIcon @change="tableModeChange"/>
            </div>
        </div>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-12 20:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-23 10:22:07
 * @Description: 漫画管理-我的漫画
 * @Company: 成都二次元动漫
 */
import { searchMixin } from '@/mixins';
import auditComicTable from './auditComicTable';
import tableIcon from '@/views/main/comicManager/myComic/components/tableIcon.vue';
import { GradesAll } from '@/constants';
import { mapState } from 'vuex';
export default {
    mixins: [searchMixin],
    components: { auditComicTable, tableIcon },
    data() {
        return {
            grades: GradesAll,
            status:
                [
                    { value: -1, name: '全部' },
                    { value: 1, name: '延迟' },
                    { value: 0, name: '未延迟' }
                ],
            activeName: 'all',
            searchDateRange: null,
            searchData: {
                queryType: 'all',
                queryText: null,
                comicGrade: -1,
                startTime: null,
                endTime: null,
                deferStatus: -1
            },
            isTable: false,
            auditType: {
                // 二审人员
                '2': [
                    { id: 'all', name: '全部', total: null },
                    { id: 'cp_auditing', name: '一审审核中', total: null },
                    { id: 'ecy_auditing', name: '二审审核中', total: null },
                    { id: 'cp_refuse', name: '一审未通过', total: null },
                    { id: 'ecy_refuse', name: '二审未通过', total: null },
                    { id: 'ecy_pass', name: '二审通过', total: null }
                ],
                // 一审人员
                '4': [
                    { id: 'all', name: '全部', total: null },
                    { id: 'cp_auditing', name: '待审核', total: null },
                    { id: 'cp_refuse', name: '未通过', total: null },
                    { id: 'ecy_auditing', name: '审核通过', total: null }
                ]
            }
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        })
    },
    watch: {
        level(newValue, oldValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.searchHandle();
                });
            }
        },
        'searchDateRange'(dateRange = []) {
            const [startTime = null, endTime = null] = dateRange | [];
            this.searchData.startTime = startTime;
            this.searchData.endTime = endTime;
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if ([2, 4].includes(vm.level)) {
                vm.searchHandle();
            }
        });
    },
    methods: {
        // 搜索
        searchHandle() {
            this.broadCastSearchEvent();
        },
        // 清除搜索
        clearSearchHandle() {
            this.resetForm('searchForm');
            this.searchData.auditStatus = null;
            this.searchHandle();
        },
        // 更新数量
        changeTypeNumber(id, status) {
            this.auditType[this.level].map(item => {
                if (item.id === id) {
                    item.total = status;
                }
            });
        },
        // 列表模式切换
        tableModeChange({ isTable }) {
            this.isTable = isTable;
        },
        // 广播查询到子组件
        broadCastSearchEvent() {
            this.auditType[this.level].map((item, index) => {
                const _data = this.$utils.cloneDeep(this.searchData);
                _data.queryType = item.id;
                _data.comicGrade = _data.comicGrade === -1 ? null : _data.comicGrade;
                this.$refs[`audit${index + 1}`][0].searchEvent(_data);
            });
        }
    }
};
</script>

<style lang="scss">
    .audit-comic{
        .search-input{
            width: 524px;
        }
        &-tab{
            height: calc(100% - 93px);
            position: relative;
            .el-tabs{
                height: 100%;
                overflow: hidden;
                .el-tabs__content{
                    height: calc(100% - 55px);
                    .el-tab-pane{
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
            .table-change-button{
                position: absolute;
                right: 0;
                top: 5px;
            }
        }
    }
</style>
