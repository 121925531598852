<template>
    <div class="audit-table">
        <div v-show="!isTable" class="audit-table-wrap" ref="table" v-loading="isloading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading" v-empty="noData">
            <template v-if="tableList.length">
                <auditComicCard
                    v-for="card in tableList"
                    ref="card"
                    @publish="showDialog"
                    :key="card.comicId"
                    :comicData="card"
                    @preview="previewHandle"
                    @search="getTableList"
                >
                </auditComicCard>
            </template>
        </div>
        <el-table v-show="isTable" class="audit-table-list" ref="listTable" :data="tableList" v-loading="isloading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading">
            <el-table-column prop="comicName" label="漫画名称" align="center"></el-table-column>
             <el-table-column label="漫画等级" align="center">
                <template slot-scope="scope">
                    {{ getGradeName(scope.row.comicGrade) }}
                </template>
             </el-table-column>
            <el-table-column prop="auditStatus" label="审核状态" align="left" width="100">
                <template slot-scope="scope">
                    <div>{{scope.row.auditStatus | auditStatusFilter}}</div>
                    <el-tooltip v-if="scope.row.auditStatus === 3" effect="light" placement="top">
                        <div slot="content">原因：{{scope.row.auditRemark}}</div>
                        <div class="color-red single-ellipsis">原因：{{scope.row.auditRemark}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="lastChapter" label="最新章节" align="center">
                <template slot-scope="scope">{{scope.row.lastChapter | chapterNameFilter}}</template>
            </el-table-column>
            <el-table-column prop="createTime" label="提交时间" align="center" width="180">
                <template slot-scope="scope">{{scope.row.createTime | timeFilter('yyyy年MM月dd日 hh:mm:ss')}}</template>
            </el-table-column>
            <el-table-column prop="description" label="操作" align="center" width="300">
                <template slot-scope="scope">
                    <el-popover placement="left-start" :title="scope.row.comicName" width="200" trigger="hover" content="">
                        <img style="width:100%" src="/static/space.gif" :key="$config.cdn + $utils.filterImagePath(scope.row.comicCover34)" v-lazy="$config.cdn + $utils.filterImagePath(scope.row.comicCover34)" alt="">
                        <el-button type="text" size="mini" slot="reference">查看漫画封面</el-button>
                    </el-popover>
                    <el-button type="text" size="mini" @click="previewHandle(scope.row)">预览</el-button>
                    <el-button v-if="hasAuditBtn(scope.row, level)" type="text" size="mini" @click="$refs.card[scope.$index].gotoAuditHandle(scope.row)">漫画审核</el-button>
                    <el-button v-if="[2].includes(level)" type="text" size="mini" @click="$refs.card[scope.$index].jumpPublishBox(scope.row)">漫画编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
        <dialogPlus :visible.sync="dialogData.visible" :title.sync="dialogData.title" width="700px" :model="dialogData.data" :heightFull="true">
            <auditComicDialog :comicData="dialogData.data" />
            <div slot="footer" align="center">
                <el-button @click="dialogCloseHandle">关 闭</el-button>
                <el-button v-if="hasAuditBtn(dialogData.data, level)" :disabled="dialogData.data.showStatus === 0" type="primary" @click="gotoAuditHandle(dialogData.data)">去审核</el-button>
            </div>
        </dialogPlus>
         <comic-publish
            @refreshData="refreshData"
            v-if="currentPublishComicData"
            :comicData.sync="currentPublishComicData"
            :platformList="platformList"
        ></comic-publish>
    </div>
</template>
<script>
import { paginationMixin, comicMixin, dialogMixin } from '@/mixins';
import roleMixin from '@/mixins/role';
import auditComicDialog from './auditComicDialog';
import comicPublish from '@/components/comic/comicPublish';
import { Grades } from '@/constants';
import { mapState } from 'vuex';

export default {
    mixins: [paginationMixin, comicMixin, dialogMixin, roleMixin],
    props: {
        isTable: Boolean,
        index: String
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        })
    },
    data() {
        return {
            isloading: false,
            noData: true,
            tableList: [],
            pubClass: [],
            dialogData: {
                data: {
                    serialNames: [],
                    comicTypeNames: [],
                    audienceNames: [],
                    themeNames: []
                }
            },
            currentPublishComicData: null,
            platformList: null // 平台列表
        };
    },
    created() {
        this.getComicPlatformList();
        this.getPublicClass().then(res => (this.pubClass = res));
    },
    methods: {
        // 获取漫画平台列表
        getComicPlatformList() {
            return this.$api('getComicPlatformList').then(({ data }) => {
                this.platformList = data.map((platform) => {
                    return {
                        platformId: platform.id,
                        name: platform.name,
                        edit: false,
                        selectTypeId: null,
                        publishTime: null
                    };
                });
            });
        },
        // 刷新界面数据，用于改变发布信息后刷新
        refreshData(comicData) {
            this.getTableList().then(() => {
                const newComic = this.tableList.find(item => item.comicId === comicData.comicId);
                this.currentPublishComicData = newComic;
            });
        },
        // 展示发布dialog
        showDialog(comicData) {
            if (this.platformList === null || this.platformList.length === 0) {
                this.$message('平台列表尚未加载完成，请稍后点击');
                return;
            }
            this.currentPublishComicData = this.$utils.cloneDeep(comicData);
        },
        // 搜索接收时间
        searchEvent(searchData) {
            this.searchData = searchData;
            this.getTableList();
        },
        // 预览
        previewHandle(comicData) {
            this.changeDialog(true, '', 0, { ...comicData, ...this.$utils.formatPublicClass(this.pubClass[0].child, comicData.classIdlist.split(',')) });
        },
        // 去审核
        gotoAuditHandle({ comicId, comicName, auditStatus }) {
            this.$router.push({ name: 'auditComicInfo', query: { comicId, comicName, auditStatus } });
        },
        // 查询列表
        getTableList() {
            this.isloading = true;
            const that = this;
            this.$api('getAuditComicList_Admin', { ...this.searchData, ...this.pagination }).then(res => {
                that.$refs.table && (that.$refs.table.scrollTop = 0);
                const { rows, pageIndex, pageSize, total, statusData } = res.data;
                this.noData = (rows.length !== 0);
                this.tableList = rows;
                this.pagination = {
                    ...this.pagination,
                    pageIndex: pageIndex,
                    pageSize: pageSize,
                    total
                };
                console.log(statusData);
                this.$emit('changeTypeNumber', this.index, this.index === 'all' ? total : statusData[this.index]);
                this.isloading = false;
            });
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        },
        getGradeName(comicGrade) {
            const grade = Grades.find(item => item.value === comicGrade);
            return grade === undefined ? '未配置等级' : grade.label;
        }
    },
    components: {
        auditComicDialog,
        comicPublish
    }
};
</script>

<style lang="scss">
    @import '../../../../styles/var';
    .audit-table{
        height: 100%;
        &-wrap{
            height: calc(100% - 48px);
            overflow-y: auto;
            position: relative;
        }
        &-list{
            height: calc(100% - 48px);
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
        }
    }
</style>
